import React from 'react'
import styled from 'styled-components'
import propTypes from 'prop-types'
import { BREAKPOINT, COLOR, SPACING, WIDTH } from '../constants'

const BlogHeroSection = styled.section`
  background-color: ${COLOR.WHITE};
`

const Wrapper = styled.div`
  max-width: ${WIDTH.LINE_LIMIT};
  display: flex;
  flex-direction: column-reverse;
  align-items: start;
  padding: ${SPACING.XXXL} ${SPACING.S} ${SPACING.S} 0;

  @media (min-width: ${BREAKPOINT.S}) {
    padding-bottom: ${SPACING.XXL};
    padding-right: ${SPACING.XXL};
    padding: 160px ${SPACING.XL} ${SPACING.L} 0;

    @media (min-width: ${BREAKPOINT.M}) {
      padding-top: ${SPACING.XXXL};
      padding-bottom: ${SPACING.L};
      max-width: '100%';
    }

    @media (min-width: ${BREAKPOINT.L}) {
      max-width: ${WIDTH.WRAPPER};
      flex-direction: row;
      align-items: center;
    }
  }
`

const Content = styled.div`
  max-width: ${WIDTH.LINE_LIMIT};
  margin-right: 0;

  @media (min-width: ${BREAKPOINT.M}) {
    margin-right: ${SPACING.XXXL};
  }
`

const BlogHero = ({ children }) => (
  <BlogHeroSection>
    <Wrapper>
      <Content>{children}</Content>
    </Wrapper>
  </BlogHeroSection>
)

BlogHero.propTypes = {
  children: propTypes.oneOfType([
    propTypes.arrayOf(propTypes.node),
    propTypes.node,
  ]).isRequired,
}

export default BlogHero
