/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import Layout from '../../components/layout'
import RichText from '../../components/RichText'
import ContentWrapper from '../../components/ContentWrapper'
import DetailContainer from '../../components/DetailContainer'
import DetailContent from '../../components/DetailContent'
import { A, P, BlogHeading } from '../../components/Typography'
import { SPACING } from '../../constants'
import BlogHero from '../../components/BlogHero'

const StyledImage = styled(Img)`
  object-fit: contain;
`

const CoverImageContainer = styled.div`
  margin-top: ${SPACING.L};
  margin-bottom: ${SPACING.L};
`

const blogPostTemplate = ({ data: { blogPost } }) => (
  <Layout title={blogPost.title} seoDescription={blogPost.seoDescription}>
    <ContentWrapper>
      <BlogHero>
        <A href="/blog">Blog</A>
        <BlogHeading>{blogPost.title}</BlogHeading>
        <P>
          by {blogPost.author.authorName}, {blogPost.publishDate}
        </P>
      </BlogHero>

      <DetailContainer>
        <DetailContent>
          <CoverImageContainer>
            <StyledImage
              fluid={blogPost.coverImage.fluid}
              alt={blogPost.coverImage.description}
            />
          </CoverImageContainer>
          {RichText(blogPost.postContent)}
        </DetailContent>
      </DetailContainer>
    </ContentWrapper>
  </Layout>
)

export const query = graphql`
  query($slug: String) {
    blogPost: contentfulBlogPost(slug: { eq: $slug }) {
      id
      title
      author {
        authorName
      }
      publishDate(formatString: "Do MMMM YYYY")
      postDescription {
        internal {
          content
        }
      }
      coverImage {
        fluid(maxWidth: 800) {
          ...GatsbyContentfulFluid
        }
        description
      }
      postContent {
        json
      }
    }
  }
`

export default blogPostTemplate
